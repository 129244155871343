import { memo } from 'react'
import { BtnAction, Img } from 'src/components/elements'
import useMountAndOpenModal from 'src/hooks/useMountAndOpenModal'
import ModalTandC from './ModalTandC'
import ModalDataPrivacy from './ModalDataPrivacy'

interface Props {
  handleOnSubmit: () => void
  disabled: boolean
  cstmTxt?: string
}

const MakePayment: React.FC<Props> = ({
  handleOnSubmit,
  disabled,
  cstmTxt,
}) => {
  const [modal, createModal] = useMountAndOpenModal()

  return (
    <div className="flex  flex-col items-center lg:items-start space-y-4 w-full pt-4 lg:pt-0">
      {modal}
      <p className="text-[12px]">
        By clicking here, you agree with{' '}
        <button
          type="button"
          onClick={() => createModal(ModalTandC)}
          className="font-bold"
        >
          Terms of Service
        </button>
        {` and `}
        <button
          type="button"
          onClick={() => createModal(ModalDataPrivacy)}
          className="font-bold"
        >
          Data Privacy
        </button>
      </p>

      <BtnAction
        disabled={disabled}
        runAction={() => handleOnSubmit()}
        className="uppercase  md:px-4"
        title={cstmTxt || 'Continue'}
      />
      <div className=" text-neutral-light-main-700 font-bold text-base flex items-center whitespace-nowrap space-x-2">
        <Img
          src="/images/artwork/techpay-shield.svg"
          alt=""
          className=" object-contain"
          draggable={false}
        />
        <p>
          Encrypted and <span className=" text-secondary">Secure</span>{' '}
          Payments
        </p>
      </div>
    </div>
  )
}

export default memo(MakePayment)
