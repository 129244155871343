import { Menu, Transition } from '@headlessui/react'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/chevron-down.svg'
import { useEffect, useState } from 'react'
import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
} from '@floating-ui/react-dom'

interface Props {
  label?: string
  name: string
  error?: string
  disabled: boolean
  value: string
  placeholder: string
  valueLabel?: string
  options: { name: string; value: string | number }[]
  onChange(x: { n: string; v: string | number }): void
  classNameInput?: string
  isRequired?: boolean
}

const FormInputDropdown = ({
  label,
  name,
  error = '',
  disabled,
  value,
  placeholder,
  options,
  onChange,
  valueLabel,
  classNameInput,
  isRequired,
}: Props) => {
  const isValid = error?.length < 1
  const [newLabel, setNewLabel] = useState(valueLabel ?? null)
  const handleSelect = (n: string, v: string | number, l: string) => {
    onChange({ n, v })
    setNewLabel(l)
  }

  const { x, y, reference, floating, strategy, refs, update } = useFloating({
    middleware: [offset(5), flip(), shift()],
    placement: 'bottom-start',
  })

  const newDisabled = disabled || options?.length <= 0

  useEffect(() => {
    if (refs.reference.current && refs.floating.current) {
      autoUpdate(refs.reference.current, refs.floating.current, update)
    }
  }, [update, refs.reference, refs.floating])

  return (
    <div
      className={[
        ' flex flex-col space-y-2 relative',
        newDisabled ? 'opacity-50 transition-opacity' : '',
      ].join(' ')}
    >
      {label && (
        <label htmlFor={name} className="font-semibold text-base">
          {label}
          {isRequired && <span className="text-spice">*</span>}
        </label>
      )}
      <Menu as="div" className=" flex min-w-[200px] relative">
        <Menu.Button
          ref={reference}
          disabled={newDisabled}
          className={[
            'h-11 w-full flex items-center justify-between px-3 rounded-lg  text-base bg-white border  outline-1',
            !isValid && !newDisabled ? 'border-spice' : '',
            isValid && !newDisabled
              ? 'border-neutral-light-main-600 focus:border-primary focus:shadow-[0_0_4px_0_rgba(123,97,255,0.5)]'
              : '',
            newDisabled && 'cursor-not-allowed',
            classNameInput,
          ].join(' ')}
        >
          <span className={[newLabel ? '' : 'opacity-70'].join(' ')}>
            {newLabel || placeholder}
          </span>

          <ArrowDownIcon className="h-[1.125rem] w-[1.125rem]" />
        </Menu.Button>

        {options && options.length > 0 && (
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? '',
              left: x ?? '',
            }}
            className="Popover z-50 w-full"
          >
            <Transition
              as="div"
              className=" rounded-lg   bg-white  border border-neutral-light-main-600"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="flex flex-col items-start focus:outline-none max-h-56 p-2 overflow-auto space-y-1">
                {options.map((item, i) => (
                  <Menu.Item key={i.toString()}>
                    {({ active }) => (
                      <button
                        type="button"
                        className={[
                          ' cursor-pointer relative  hover:bg-primary hover:text-white w-full text-left p-2 rounded-lg',
                          (active || value === item.value) &&
                            ' bg-primary text-white',
                        ].join(' ')}
                        id={item.value.toString()}
                        onClick={() =>
                          handleSelect(name, item.value, item.name)
                        }
                        onKeyUp={(e) => {
                          if (e.code === 'Enter') {
                            handleSelect(name, item.value, item.name)
                          }
                        }}
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </div>
        )}

        {error && !disabled && (
          <div className=" msgFormField text-spice">{error}</div>
        )}
      </Menu>
    </div>
  )
}

export default FormInputDropdown
