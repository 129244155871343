import axios from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import { sha256 } from 'js-sha256'
import { createElement, lazy, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiToUsed from 'src/helpers/apiToUsed'
import { ChannelModel, InitDataModel } from 'src/models/InitDataModel'
import ChoosePayment from 'src/pages-content/checkout/ChoosePayment'
import PaymentDetail from 'src/pages-content/checkout/PaymentDetail'

const ErrorPage = lazy(() => import('src/pages-content/error'))

const Confirmation = lazy(
  () => import('src/pages-content/checkout/Confirmation')
)

const ExpiredScreen = lazy(() => import('./error/ExpiredScreen'))

interface Props {
  setActiveStep: (x: number) => void
  activeStep: number
}

const CheckoutContent = ({ setActiveStep, activeStep }: Props) => {
  const { merchant, code } = useParams()
  const [loading, setLoading] = useState(true)
  const [selectedChannel, setSelectedChannel] = useState<ChannelModel | null>(
    null
  )
  const [isReady, setIsReady] = useState(false)
  const [hashData, setHashData] = useState<any>(null)
  const [initialData, setInitData] = useState<any>(null)
  const [finalData, setFinalData] = useState(null)
  const [errorData, setErrorData] = useState({
    errorCode: null,
    errorMessage: null,
    errorFormField: null,
  })

  useEffect(() => {
    const handleFetchInitData = async () => {
      setLoading(true)
      try {
        const tobeHash = (code + '@' + merchant).split('').reverse().join('')
        const newhashData = sha256(tobeHash)
        setHashData(newhashData)
        const { data }: { data: { data: InitDataModel } } = await axios({
          method: 'get',
          baseURL: apiToUsed,
          url: `/v1/external/initial?stringify_id=${code}&organization=${merchant}`,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${newhashData}`,
          },
        })
        if (data.data?.is_used === true) {
          const { data } = await axios({
            method: 'get',
            url: `/v1/external/final?stringify_id=${code}&organization=${merchant}`,
            baseURL: apiToUsed,
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${newhashData}`,
            },
          })
          setFinalData(data.data)
        }
        setInitData(data.data)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        const errors = {
          errorCode: error.response?.status || error.code,
          errorFormField: error.response?.data.errors || null,
          errorMessage: error.response?.data.message || error.message,
        }
        setErrorData(errors)
      }
    }

    if (isReady) {
      handleFetchInitData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  useEffect(() => {
    if (code && merchant) setIsReady(true)
    return () => {
      setIsReady(false)
    }
  }, [code, merchant])

  if (!loading && !initialData?.is_used && initialData?.is_expired) {
    return (
      <div className="flex flex-col items-center">
        {createElement(ExpiredScreen)}
        {!!initialData?.data?.redirects?.failure && (
          <a
            href={initialData?.data?.redirects?.failure}
            className={[
              'text-base text-center font-bold uppercase bg-primary text-white rounded-md mx-auto',
              'my-2 py-2 px-12 rounded max-w-md',
            ].join(' ')}
          >
            Back to merchant
          </a>
        )}
      </div>
    )
  }

  if (!loading && errorData.errorCode) {
    return (
      <div className="flex flex-col items-center">
        {createElement(ErrorPage, { errorCode: errorData?.errorCode })}
        {!!initialData?.data?.redirects?.failure && (
          <a
            href={initialData?.data?.redirects?.failure}
            className={[
              'text-base text-center font-bold uppercase bg-primary text-white rounded-md mx-auto',
              'my-2 py-2 px-12 rounded max-w-md',
            ].join(' ')}
          >
            Back to merchant
          </a>
        )}
      </div>
    )
  }

  if (!loading && finalData) {
    return createElement(Confirmation, {
      initialData: initialData,
      finalData: finalData,
      setActiveStep,
    })
  }

  if (selectedChannel && activeStep === 2) {
    return createElement(PaymentDetail, {
      setFinalData,
      initialData: initialData,
      hashData,
      rndmUnqStrng: code ?? '',
      selectedChannel,
      setSelectedChannel,
      setActiveStep,
    })
  }

  return (
    <>
      <AnimatePresence>
        {loading && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex items-center justify-center fixed inset-0 bg-white z-50"
          >
            <span className="spinner spinner--quarter h-12 w-12"></span>
          </motion.div>
        )}
      </AnimatePresence>

      {!loading && (
        <ChoosePayment
          hashData={hashData}
          setFinalData={setFinalData}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          setActiveStep={setActiveStep}
          initialData={initialData}
        />
      )}
    </>
  )
}

export default CheckoutContent
