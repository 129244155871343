import { Link } from 'react-router-dom'

interface Props {
  linkTo: string
  title: string
  className?: string
  isOutline?: boolean
}

const BtnLink: React.FC<Props> = ({ linkTo, title, className, isOutline }) => {
  return (
    <Link
      to={linkTo}
      className={[
        'text-base text-center font-bold uppercase ',
        'my-2 p-[10px] rounded  w-full  border ',
        isOutline
          ? 'border-primary text-primary '
          : 'border-transparent bg-primary text-white',
        isOutline && 'hover:bg-primary hover:text-white',
        className,
      ].join(' ')}
    >
      {title}
    </Link>
  )
}

export default BtnLink
