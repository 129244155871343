interface Props {
  title?: string
  className?: string
  runAction(): void
  disabled?: boolean
  isOutline?: boolean
}

const BtnAction: React.FC<Props> = ({
  title,
  className,
  runAction,
  disabled,
  isOutline,
}) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={[
        'text-base text-center font-bold uppercase ',
        'my-2 p-[10px] rounded max-w-md w-full  border ',
        isOutline
          ? 'border-primary text-primary '
          : 'border-transparent bg-primary text-white',
        isOutline && 'hover:bg-primary hover:text-white',
        disabled ? 'bg-opacity-50 text-opacity-50' : 'cursor-pointer',
        className,
      ].join(' ')}
      onClick={() => runAction()}
    >
      {title}
    </button>
  )
}

export default BtnAction
