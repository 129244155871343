import { BrowserRouter, Routes, Route } from 'react-router-dom'
import axios from 'axios'
import NotFound from './pages/404'
import CheckoutPage from './pages/checkout'

axios.defaults.headers.get.Accept = 'application/json'
axios.defaults.headers.post.Accept = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:merchant/:code" element={<CheckoutPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
