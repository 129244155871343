import { SyntheticEvent, useEffect, useState } from 'react'
import axios from 'axios'
import { ReactComponent as BackArrowIcon } from 'src/assets/icons/backArrow.svg'
import { useParams } from 'react-router-dom'
import useMountAndOpenModal from 'src/hooks/useMountAndOpenModal'
import UnexpectedErrorModal from 'src/components/shared/UnexpectedErrorModal'
import { BtnSubmit, Form, Img } from 'src/components/elements'
import apiToUsed from 'src/helpers/apiToUsed'
import { ChannelModel, InitDataModel } from 'src/models/InitDataModel'
import PaymentSummary from '../ChoosePayment/PaymentSummary'
import DebitCreditCardForm from './DebitCreditCardForm'
import ModalTandC from './ModalTandC'
import ModalDataPrivacy from './ModalDataPrivacy'

interface Props {
  hashData: string
  initialData: InitDataModel
  setFinalData(data: any): void
  rndmUnqStrng: string
  setSelectedChannel: (x: ChannelModel | null) => void
  selectedChannel: ChannelModel
  setActiveStep: (x: number) => void
}

const ChoosePayment: React.FC<Props> = ({
  initialData,
  hashData,
  setFinalData,
  rndmUnqStrng,
  setSelectedChannel,
  selectedChannel,
  setActiveStep,
}) => {
  const { merchant } = useParams()
  const [modal, createModal] = useMountAndOpenModal()
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    country_code: 'PH',
  })

  const [errorData, setErrorData] = useState<any>({
    errorCode: null,
    errorFormField: null,
    errorMessage: null,
  })

  const handleOnSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const formData =
      selectedChannel.fields.length > 0
        ? {
            ...form,
            mobile_number: form?.mobile_number
              ?.replace(/[^.\d]/g, '')
              .slice(-10),
          }
        : {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
          }

    const subBillerField = {
      stringify_id: rndmUnqStrng,
    }

    const dataToSubmit = {
      organization: merchant,
      channel_code: selectedChannel?.code,
      ...formData,
      ...subBillerField,
    }

    if (!selectedChannel) {
      setLoading(false)
      return createModal(UnexpectedErrorModal, {
        errMsg: 'Select Payment Method',
      })
    } else {
      try {
        const { data } = await axios({
          method: 'post',
          baseURL: apiToUsed,
          data: dataToSubmit,
          url: '/v1/external/checkout',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${hashData}`,
          },
        })
        if (data.data.web_payment_url) {
          return (window.location = data.data.web_payment_url)
        }
        setLoading(false)
        return setFinalData(data?.data)
      } catch (err: any) {
        const errors = {
          errorCode: err.response?.status || err.code,
          errorFormField: err.response?.data.errors,
          errorMessage: err.response?.data.message || err.message,
        }

        setLoading(false)
        if (errors.errorFormField?.channel_code?.[0]) {
          return createModal(UnexpectedErrorModal, {
            errMsg: errors.errorFormField?.channel_code[0],
          })
        }
        if (errors.errorCode !== 422) {
          return createModal(UnexpectedErrorModal, {
            errMsg: errors.errorMessage,
          })
        }

        if (errors.errorFormField?.stringify_id?.[0]) {
          return window.location.reload()
        }

        const element = document.getElementById(
          errors.errorFormField &&
            Object.keys(errors.errorFormField).find(
              (key) => errors.errorFormField[key].length > 0
            )
        )

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }

        return setErrorData(errors)
      }
    }
  }

  const handleBack = () => {
    setActiveStep(1)
    setSelectedChannel(null)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
    setErrorData({
      errorCode: null,
      errorFormField: null,
      errorMessage: null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel?.fields?.length])

  return (
    <div className="grid auto-rows-min grid-cols-1 lg:grid-cols-[1fr,24.375rem] w-full gap-4 xl:gap-8 lg:gap-y-0 flex-1 items-start">
      {modal}
      <div
        className={[
          'flex flex-col  bg-white p-8 sm:p-12 rounded-xl justify-between max-w-[43.75rem] mx-auto w-full shadow-[0px_4px_20px_rgba(0,_0,_0,_0.1)]',
        ].join(' ')}
      >
        <div className=" space-y-12">
          <Form onSubmit={handleOnSubmit} className="flex flex-col space-y-8">
            <div className="flex items-center space-x-2">
              <button
                className="flex  items-center space-x-2  text-primary"
                type="button"
                onClick={() => handleBack()}
              >
                <BackArrowIcon className="fill-current" />
              </button>
              <div className=" font-bold text-lg text-primary">
                Enter Payment Details<span className="text-spice">*</span>
              </div>
            </div>

            <div className=" border-2 border-primary rounded-xl p-3 flex items-center text-base bg-[#D9EEFF]">
              <Img
                src={selectedChannel.logo}
                alt={selectedChannel.name}
                height="70"
                width="70"
                className=" rounded-xl object-contain mr-4"
              />
              <div>
                <div className="font-bold">{selectedChannel.name}</div>
                <div>{selectedChannel.category.name}</div>
              </div>
            </div>

            <DebitCreditCardForm
              loading={loading}
              form={form}
              setForm={setForm}
              errorData={errorData}
              setErrorData={setErrorData}
              isDebitCreditCard={selectedChannel.fields.length > 0}
            />
            <div className="flex items-center flex-wrap justify-between mt-8 gap-4">
              <p className="text-[12px]">
                By clicking “PAY NOW” , you agree with <br />
                <button
                  type="button"
                  onClick={() => createModal(ModalTandC)}
                  className="font-bold"
                >
                  Terms of Service
                </button>
                {` and `}
                <button
                  type="button"
                  onClick={() => createModal(ModalDataPrivacy)}
                  className="font-bold"
                >
                  Data Privacy
                </button>
              </p>

              <BtnSubmit
                disabled={loading || !selectedChannel}
                className="uppercase  md:px-4 max-w-[214px] w-full"
                title={'PAY NOW'}
              />
            </div>
          </Form>
        </div>
      </div>
      <div
        className={[
          'bg-white p-8 rounded-xl  max-w-[43.75rem] mx-auto w-full flex flex-col space-y-8 shadow-[0px_4px_20px_rgba(0,_0,_0,_0.1)] lg:sticky lg:top-[10.125rem] lg:row-start-auto',
        ].join(' ')}
      >
        <PaymentSummary
          initialData={initialData}
          selectedChannel={selectedChannel}
        />
      </div>
    </div>
  )
}

export default ChoosePayment
