import { Modal } from 'src/components/elements'
import { Ref } from 'react'
import { ModalActionModel } from 'src/models/modal-action-model'

interface propsModel {
  modalRef: Ref<ModalActionModel>
}

const ModalTandC: React.FC<propsModel> = ({ modalRef }) => {
  return (
    <Modal
      ref={modalRef}
      isSmallModal={true}
      title="Terms and Conditions	of Use"
      maxWidth={'max-w-[800px]'}
    >
      <ul className="list-inside text-xs text-justify flex flex-col space-y-4">
        <li>
          <b>1. Acceptance of Terms</b>
          <p>
            These terms and conditions ("Terms") govern your use of Techwave
            Technologies Corporation or TechPay website ("Website"), owned and
            operated by Techwave Technologies Corporation ("Company"). By
            accessing or using the Website, you agree to be bound by these
            Terms. If you do not agree to these Terms, please refrain from using
            the Website.
          </p>
        </li>
        <li>
          <b>2. Use of the Website</b>
          <p>
            2.1. You agree to use the Website only for lawful purposes and in a
            manner that does not infringe upon any law or against the rights of
            others or restrict or inhibit anyone else's use and enjoyment of the
            Website.
          </p>
        </li>
        <li>
          <b>3. Intellectual Property</b>
          <p>
            3.1. All content on the Website, including but not limited to text,
            graphics, logos, images, audio clips, video clips, data
            compilations, and software, is the property of the Company or its
            content suppliers and is protected by Philippine and international
            copyright laws.
          </p>
          <p>
            3.2. You may not reproduce, distribute, modify, create derivative
            works of, publicly display, publicly perform, republish, download,
            store, or transmit any of the material on our Website without the
            prior written consent of the Company or as permitted by applicable
            law.
          </p>
        </li>
        <li>
          <b>4. User-Generated Content</b>
          <p>
            4.1. Any content you submit to the Website, including but not
            limited to comments, reviews, or other contributions, is subject to
            the Company's approval and may be used by the Company for
            promotional or other purposes.
          </p>
          <p>
            4.2. You represent and warrant that you own or control all rights in
            and to the content you submit and that the content is accurate, not
            misleading, and does not violate these Terms or any applicable laws.
            You shall accept full responsibility for all transactions processed
            through the use of such information whether or not processed with
            your knowledge or by your authority.
          </p>
          <p>
            4.3. The Company may, from time to time, provide offers from its
            various partners and merchants based on your user profile.
          </p>
        </li>
        <li>
          <b>5. Limitation of Liability</b>
          <p>
            5.1. The Company is not responsible for any damages resulting from
            your use of the Website, including but not limited to direct,
            indirect, incidental, consequential, special, or punitive damages
          </p>
          <p>
            5.2. The Website is provided "as is" and without warranties of any
            kind, whether express or implied.
          </p>
          <p>
            5.3. You acknowledge that the Company is only a third-party payment
            processor. Any terms or conditions for the product or service that
            you purchase using our payment facility is strictly between you and
            the merchant.
          </p>
          <p>
            5.4. We shall not be held liable for failure of the merchant to
            deliver the said product or service as advertised.
          </p>
          <p>
            5.5. The Company is released from all liability arising from the
            product or service purchase you are about to make, as all liability
            shall reside with the merchant.
          </p>
          <p>
            5.6. The Company shall not be responsible for any charges imposed on
            you or any other action taken against you by a merchant arising from
            non-processing of your payment due to your failure to follow our
            exact payment instructions.
          </p>
          <p>
            5.7. The Company shall be indemnified and not be held liable against
            any and all proceedings, claims, losses, damages or expenses,
            including legal costs, that arise from your use of our system.
          </p>
          <p>
            5.8. Your access to the Company through third-party network
            providers is subject to their respective terms and conditions. The
            security of data transmitted through such networks is covered by the
            governing policies of the network provider
          </p>
        </li>
        <li>
          <b>6. Payments</b>

          <p>
            6.1. All payments made using the Company’s systems and applications
            are final and executory. However, the Company reserves the right to
            not process a transaction due to internal reasons. Such payments may
            be subject to refund.
          </p>
          <p>
            6.2. We shall not be held liable for delays in processing of your
            payment due to corresponding delays, system problems, internal
            maintenance, or maintenance with our payment collection partners.
            The Company will not be compelled to explain the reasons for such
            incidents.
          </p>
          <p>
            6.3. Excess portion of your payment is forfeited. Payments less than
            the amount due will not be processed.
          </p>
        </li>
        <li>
          <b>7. Governing Law and Jurisdiction</b>
          <p>
            7.1. These Terms shall be governed by and construed in accordance
            with the laws of the Philippines.
          </p>
          <p>
            7.2. Any disputes arising out of or relating to these Terms or the
            use of the Website shall be subject to the exclusive jurisdiction of
            the courts located in Pasig City
          </p>
        </li>
        <li>
          <b>8. Changes to Terms</b>
          <p>
            The Company reserves the right to change, modify, add, or remove
            portions of these Terms at any time. It is your responsibility to
            check these Terms periodically for changes.
          </p>
        </li>
        <li>
          <b>9. Contact Information</b>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at techwave.com.ph.
          </p>
        </li>
        <li>
          <b>10. Separability Clause</b>
          <p>
            If any portion or provision of this terms and conditions be declared
            void, the remaining portions or provisions thereof shall not be
            affected by such declaration.
          </p>
        </li>
      </ul>
    </Modal>
  )
}

export default ModalTandC
