import { ReactComponent as PaperIcon } from 'src/assets/icons/paper.svg'
import { useState } from 'react'

interface Props {
  text: string
  className?: string
}

const CopyText: React.FC<Props> = ({ text, className }) => {
  const [message, setMessage] = useState('')
  const copyToClipBoard = async (textCopy: string) => {
    try {
      await navigator.clipboard.writeText(textCopy)
      setMessage('Copied')
    } catch (error) {
      setMessage('Failed to Copy')
    }
  }

  return (
    <div className="relative">
      <button
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.code === 'Enter') {
            copyToClipBoard(text)
          }
        }}
        onClick={() => copyToClipBoard(text)}
        className={[
          'relative flex items-center border border-[#DDDDDD] rounded justify-center text-sm p-1 space-x-1',
          className,
        ].join(' ')}
      >
        <PaperIcon className=" w-5 h-5  cursor-pointer fill-current stroke-0" />
        <div>{message || 'Copy'}</div>
      </button>
    </div>
  )
}

export default CopyText
