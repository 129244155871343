import { Img } from 'src/components/elements'
import { ReactElement } from 'react'

const NotFound = (): ReactElement => {
  return (
    <div className="flex flex-col justify-center text-white pb-12">
      <div className="flex items-start justify-start max-w-[66.875rem] w-full mx-auto">
        <a href={window.location.pathname} className="z-20">
          <Img
            draggable={false}
            className="object-contain max-w-[150px] sm:max-w-none"
            src="/images/logo/techpay.svg"
            alt="TechPay"
            width="208"
            height="114"
          />
        </a>
      </div>
      <div className="relative flex flex-col space-y-4 ">
        <div className="flex flex-col items-center  bg-primary">
          <Img src="/images/artwork/404.png" alt="404 techpay" />
        </div>
        <div className=" max-w-[27.5rem] mx-auto font-semibold space-y-4 pt-12">
          <div className=" text-primary text-3xl text-center">
            Ooops! Looks like you’re lost
          </div>
          <p className=" text-xl text-custom-black px-4">
            This page doesn’t exist or was removed. <br /> We suggest you go
            back to home.
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFound
