import { Ref } from 'react'
import { ModalActionModel } from 'src/models/modal-action-model'
import { Modal } from '../elements'

interface propsModel {
  errMsg?: string
  modalRef: Ref<ModalActionModel>
}

const UnexpectedErrorModal: React.FC<propsModel> = ({ errMsg, modalRef }) => {
  return (
    <Modal ref={modalRef} isSmallModal={true} title="Something went wrong">
      <p className=" text-center py-8 text-base">{errMsg}</p>
    </Modal>
  )
}

export default UnexpectedErrorModal
