import { memo } from 'react'
import { Img } from 'src/components/elements'
import round from 'src/helpers/round'
import { ChannelModel, InitDataModel } from 'src/models/InitDataModel'

interface Props {
  initialData: InitDataModel
  selectedChannel: ChannelModel | null
}

const PaymentSummary: React.FC<Props> = ({ initialData, selectedChannel }) => {
  const getServiceFee = (): number => {
    const service_fee_details = selectedChannel
      ? selectedChannel?.service_fee_details
      : null

    if (service_fee_details) {
      if (service_fee_details.is_fee_percentage === true) {
        const sub_amount = initialData?.subtotal_amount
        return service_fee_details.service_fee * 0.01 * sub_amount
      }
      return service_fee_details.service_fee
    }

    return 0
  }

  return (
    <>
      <div className="flex flex-col items-center space-y-2 ">
        <Img
          height="60"
          width="60"
          draggable={false}
          className="object-contain shadow-[0_0_4px_0_rgba(109,91,208,0.15)] rounded-md p-2 h-[3.75rem] w-[3.75rem]"
          src={
            initialData?.biller.organization_logo
              ? initialData?.biller?.organization_logo
              : '/images/artwork/broken-image.svg'
          }
          alt={
            initialData?.biller.organization
              ? initialData?.biller?.organization
              : 'techpay'
          }
        />
        <div className="font-semibold">{initialData?.biller?.organization}</div>
        <div className="font-semibold text-[12px] uppercase bg-[#FFF2C9] border rounded px-6 py-2">
          INITIAL
        </div>
      </div>

      <div className=" text-base flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div>(Paying For)</div>
          <div>
            <span className="pr-2">₱</span>
            {round(initialData?.subtotal_amount, 2)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div>Transaction Fee</div>
          <div>
            <span className="pr-2">₱</span>
            {round(getServiceFee(), 2)
              ?.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
        <div className="flex items-center justify-between text-primary font-bold">
          <div>Total Amount</div>
          <div className="font-semibold">
            <span className="pr-2">₱</span>
            {round(getServiceFee() + initialData?.subtotal_amount, 2)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(PaymentSummary)
