import { memo } from 'react'
import { Img } from 'src/components/elements'

const steps = [
  {
    id: 1,
    name: 'Choose Payment',
  },
  {
    id: 2,
    name: 'Payment Details',
  },
  {
    id: 3,
    name: 'Confirmation',
  },
]

interface Props {
  activeStep: number
}

const Nav = ({ activeStep }: Props) => {
  return (
    <nav className=" bg-background w-full  lg:sticky lg:top-0 z-50">
      <div className="grid grid-cols-1 md:grid-cols-2 items-center md:h-[7.125rem]  max-w-[66.875rem] w-full mx-auto px-4">
        <div className="flex items-start justify-center md:justify-start">
          <a href={window.location.pathname} className="z-20">
            <Img
              draggable={false}
              className="object-contain max-w-[150px] sm:max-w-none"
              src="/images/logo/techpay.svg"
              alt="TechPay"
              width="208"
              height="114"
            />
          </a>
        </div>

        <div className="hidden md:flex justify-end ">
          <div className="flex w-full max-w-md mx-auto px-4 ">
            {steps.map((x) => (
              <div
                key={x.id}
                className={[
                  'flex items-center',
                  x.id !== 1 ? 'w-full' : '',
                ].join(' ')}
              >
                {x.id !== 1 && (
                  <div
                    className={[
                      ' h-1 w-full flex-1',
                      activeStep >= x.id ? 'bg-primary' : 'bg-[#AEAEAE]',
                    ].join(' ')}
                  ></div>
                )}
                <div className="flex items-center flex-col text-sm relative">
                  <div
                    className={[
                      'h-[2.375rem] w-[2.375rem] rounded-full flex items-center justify-center font-bold text-base text-white select-none',
                      activeStep >= x.id ? 'bg-primary' : 'bg-[#AEAEAE]',
                    ].join(' ')}
                  >
                    {x.id}
                  </div>
                  <div
                    className={[
                      'font-bold absolute top-full pt-1 whitespace-nowrap',
                      activeStep >= x.id ? 'text-primary' : 'text-[#AEAEAE]',
                      activeStep !== x.id ? 'hidden md:block' : '',
                    ].join(' ')}
                  >
                    {x.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default memo(Nav)
