import { Modal } from 'src/components/elements'
import { Ref } from 'react'
import { ModalActionModel } from 'src/models/modal-action-model'

interface propsModel {
  modalRef: Ref<ModalActionModel>
}

const ModalTandC: React.FC<propsModel> = ({ modalRef }) => {
  return (
    <Modal
      ref={modalRef}
      isSmallModal={true}
      title="Data Privacy"
      maxWidth={'max-w-[800px]'}
    >
      <p className=" text-justify text-xs">
        This privacy policy discloses the privacy practices of Techwave
        Technologies Corporation payment system known as “TechPay.” The private
        policy of Techpay covers information collected by all online websites,
        mobile and online applications, softwares, or portals, including those
        that are integrated to our merchant partners! websites (referred to as
        “TechPay platforms”). Any information shared after the user logged off
        the system is not included.
        <br />
        <br />
        Since this private policy is disclosed in all Techwave Technologies
        Corporation or TechPay platforms, the fact of visiting and continuing to
        use the platform is considered to be the user’s act of accepting and
        consenting to the practices described in this Policy. TechPay’s private
        policy protects your personal information under the Data Privacy Act of
        2012 and the law of the Philippines.
        <br />
        <br />
        <b>Information Collection</b> <br />
        Techwave Technologies Corporation act as both data Data Collector and
        Processor. Techwave Technologies Corporation is the sole owner of the
        information collected in all TechPay Platforms. Information is collected
        from any of the following methods, namely: email, imbedded messaging
        functions, payment selection pages, fill out forms, mobile applications,
        and other services.
        <br />
        <br />
        Information collected and processed may include but not limited to
        personal and or sensitive information like name, date of birth, gender,
        nationality, contact number, address. It may also include government
        identifiers such as driver license, passport number, or Social
        Securities Numbers. It also include financial details such as credit
        card, bank, payment details, billing address, email addresses, and other
        contact details.
        <br />
        <br />
        <b>Information Use and Sharing</b> <br />
        The information you have given to use will be used to respond to you. We
        assure you that we will not sell nor rent the information gathered from
        you to anyone. Additionally, it will not be shared with any third party
        outside our organization, other than as necessary to fulfill your
        request and and efficiently provide our services to you, e.g to check
        payment validation. User Experience is also gathered to provide your
        with better services.
        <br />
        <br />
        Unless opted out through unsubscribing or direct contact, we will use
        your information for marketing and other purposes. Your information like
        phone number and email may be used to may receive special offers and
        promotions, or other wise contact you about services and information
        that we think may be of interest.
        <br />
        <br />
        <b>Security</b> <br />
        Techwave Technologies Corporation or TechPay ensures precautions are in
        place to protect your information by enforcing organizational and
        physical security measures. Only employees who need the information to
        perform a specific task are granted access to personally identifiable
        information. Sensitive information are restricted to only highly
        authorized employees. All employees, with direct or indirect access to
        both offline and online information, are covered by Non-Disclosure
        Agreements. Both servers and computers that store these information are
        kept in a secure environment. Collected information such as bank
        information or payment details are encrypted and transmitted to us
        securely.
        <br />
        <br />
        As the Data Subject, you have the right to be informed, object, access,
        correct, rectify and erase any personal or sensitive information
        collected. If Techwave Technologies Corporation or TechPay is required
        by law to process the information for any legal purpose. We may notify
        you of this requirement, unless it is prohibited by law for providing
        such notice.
        <br />
        <br />
        <b>Links</b> <br />
        Techwave Technologies Corporation or TechPay is not responsible for the
        privacy practices of such other sites. This privacy statement applies
        solely to information collected by this website.
        <br />
        <br />
        <b>Use of Cookies</b>
        <br />
        This site uses cookies and other tracking mechanisms, to track
        information about your use of our Website and Services, including
        combining, analyzing and collating personal information collected.
        Cookies allows easier navigation and continuous use of this website will
        signify your acceptance and consent that allows us to use such cookies
        needed for the efficient use of our services.
        <br />
        <br />
        <b>Changes to Privacy Policy</b>
        <br />
        Minor changes of the Privacy Policy may occur in Techwave Technologies
        Corporation or TechPay from time to time. All changes will be posted on
        our respective pages. You will always be provided notice if these
        changes are material and, if we are required by law, we will also obtain
        your consent.
        <br />
        <br />
        <b>Disclaimer</b>
        <br />
        Techwave Technologies Corporation or TechPay reserves the right to amend
        its policy, and any content on its website without prior notice.
        <br />
        <br />
        <b>Contact</b> <br />
        Data Privacy Officer and email address: dpo@techwave.com.ph
      </p>
    </Modal>
  )
}

export default ModalTandC
