/* eslint-disable react-hooks/exhaustive-deps */
import provincesData from 'src/libs/address/provinces.json'
import citiesData from 'src/libs/address/cities.json'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import countryData from 'src/libs/address/country.json'
import {
  FormInputDropdown,
  FormInputMobileNumber,
  FormInputText,
} from 'src/components/elements'

interface PropModel {
  loading: boolean
  isDebitCreditCard: boolean
  form: {
    first_name: string
    last_name: string
    mobile_number: string
    email: string
    address: string
    city: string
    state: string
    zip_code: string
    country_code: string
  }
  setForm(data: {
    first_name: string
    last_name: string
    mobile_number: string
    email: string
    address: string
    city: string
    state: string
    zip_code: string
    country_code: string
  }): void
  errorData: any
  setErrorData(data: any): void
}

const DebitCreditCardForm: React.FC<PropModel> = ({
  loading,
  errorData,
  setForm,
  form,
  setErrorData,
  isDebitCreditCard,
}) => {
  const handleOnChange = ({ n, v }: { n: string; v: string }) => {
    setForm({
      ...form,
      [n]: v,
    })
    setErrorData({
      ...errorData,
      errorFormField: {
        ...errorData.errorFormField,
        [n]: null,
      },
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      state: '',
      city: '',
      zip_code: '',
    })
  }, [form.country_code])

  useEffect(() => {
    setForm({
      ...form,
      city: '',
    })
  }, [form.state])

  useEffect(() => {
    setForm({
      ...form,
      zip_code: '',
    })
  }, [form.city])

  useEffect(() => {
    if (form.city !== '') {
      setForm({
        ...form,
        zip_code:
          citiesData[form.state as keyof typeof citiesData].find(
            (x) => x.id.toString() === form.city.toString()
          )?.zip_code ?? '',
      })
      setErrorData({
        ...errorData,
        errorFormField: {
          ...errorData.errorFormField,
          zip_code: null,
        },
      })
    }
  }, [form.city])

  useEffect(() => {
    setForm({
      ...form,
      state: '',
      city: '',
      zip_code: '',
    })
  }, [form.country_code])

  useEffect(() => {
    setForm({
      ...form,
      country_code: 'PH',
    })
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full"
    >
      <FormInputText
        isRequired
        name="first_name"
        value={form.first_name}
        onChange={(e) =>
          handleOnChange({ n: e.target.name, v: e.target.value })
        }
        error={errorData.errorFormField?.first_name?.[0]}
        label="First Name"
        disabled={loading}
        placeholder="Enter First Name"
        classNameInput="scroll-mt-12"
      />
      <FormInputText
        isRequired
        name="last_name"
        value={form.last_name}
        onChange={(e) =>
          handleOnChange({ n: e.target.name, v: e.target.value })
        }
        error={errorData.errorFormField?.last_name?.[0]}
        label="Last Name"
        disabled={loading}
        placeholder="Enter Last Name"
        classNameInput="scroll-mt-12"
      />

      {isDebitCreditCard && (
        <div className="md:col-span-2">
          <FormInputMobileNumber
            name="mobile_number"
            isRequired
            value={form.mobile_number}
            onChange={handleOnChange}
            error={errorData.errorFormField?.mobile_number?.[0]}
            label="Mobile Number"
            disabled={loading}
            placeholder="Enter Mobile Number"
            classNameInput="scroll-mt-12"
          />
        </div>
      )}

      <div className="md:col-span-2">
        <FormInputText
          name="email"
          isRequired
          value={form.email}
          onChange={(e) =>
            handleOnChange({ n: e.target.name, v: e.target.value })
          }
          error={errorData.errorFormField?.email?.[0]}
          label="Email Address"
          disabled={loading}
          placeholder="Enter Email Address"
          classNameInput="scroll-mt-12"
        />
      </div>
      {isDebitCreditCard && (
        <>
          <FormInputDropdown
            name="country_code"
            isRequired
            valueLabel={
              countryData?.[form.country_code as keyof typeof countryData].name
            }
            value={form.country_code}
            disabled
            onChange={handleOnChange}
            error={errorData.errorFormField?.country_code?.[0]}
            label="Country"
            placeholder="Select Country"
            options={Object.keys(countryData).map((x) => {
              return {
                id: countryData[x as keyof typeof countryData].code,
                value: countryData[x as keyof typeof countryData].code,
                name: countryData[x as keyof typeof countryData].name,
              }
            })}
            classNameInput="scroll-mt-12"
          />
          <FormInputDropdown
            name="state"
            isRequired
            value={form.state}
            onChange={handleOnChange}
            error={errorData.errorFormField?.state?.[0]}
            label="Province"
            disabled={loading}
            placeholder="Select Province"
            options={provincesData.map((p) => {
              return { id: p.id, value: p.id, name: p.name }
            })}
            classNameInput="scroll-mt-12"
          />

          <FormInputDropdown
            name="city"
            isRequired
            value={form.city}
            disabled={!form.state || form.state === '' || loading}
            onChange={handleOnChange}
            error={errorData.errorFormField?.city?.[0]}
            label="City/Municipality"
            placeholder="Select City"
            options={
              form.state && form.state !== ''
                ? citiesData[form.state as keyof typeof citiesData].map((p) => {
                    return {
                      id: p.id,
                      value: p.id,
                      name: p.name,
                    }
                  })
                : []
            }
            classNameInput="scroll-mt-12"
          />

          <FormInputText
            name="zip_code"
            isRequired
            value={form.zip_code}
            onChange={(e) =>
              handleOnChange({ n: e.target.name, v: e.target.value })
            }
            error={errorData.errorFormField?.zip_code?.[0]}
            label={form.country_code === 'PH' ? 'Zip Code' : 'Zip/Postal Code'}
            disabled={
              (form.city === '' || loading) && form.country_code === 'PH'
            }
            placeholder={
              form.country_code === 'PH'
                ? 'Enter zip code'
                : 'Enter Zip Code/Postal Code'
            }
            classNameInput="scroll-mt-12"
          />
          <div className=" md:col-span-2">
            <FormInputText
              isRequired
              name="address"
              value={form.address}
              onChange={(e) =>
                handleOnChange({ n: e.target.name, v: e.target.value })
              }
              error={errorData.errorFormField?.address?.[0]}
              label="Address"
              disabled={loading}
              placeholder="Enter address"
              classNameInput="scroll-mt-12"
            />
          </div>
        </>
      )}
    </motion.div>
  )
}

export default DebitCreditCardForm
