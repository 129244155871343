import {
  useState,
  forwardRef,
  useImperativeHandle,
  Ref,
  useEffect,
  useRef,
} from 'react'
import { ReactComponent as XIcon } from 'src/assets/icons/close.svg'
import ReactDOM from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'
import FocusTrap from 'focus-trap-react'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { ModalActionModel } from 'src/models/modal-action-model'

interface props {
  title?: string
  children?: React.ReactNode
  className?: { title: string }
  closeAction?(): void
  isDisabledCloseIcon?: boolean
  isSmallModal?: boolean
  maxWidth?: string
  isNoOutsideClick?: boolean
}

const variants = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  entrance: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.8,
  },
}

const Modal = forwardRef(
  (
    {
      title,
      children,
      className,
      closeAction,
      maxWidth,
      isNoOutsideClick,
    }: props,
    ref: Ref<ModalActionModel>
  ) => {
    const [display, setdisplay] = useState(false)
    const open = (): void => {
      setdisplay(true)
    }

    const modalContentRef = useRef(null)

    const close = (): void => {
      setdisplay(false)
      if (closeAction) {
        closeAction()
      }
    }
    useImperativeHandle(ref, () => {
      return {
        openModal: () => open(),
        closeModal: () => close(),
      }
    })

    useOnClickOutside(modalContentRef, () => {
      if (!isNoOutsideClick) {
        close()
      }
    })

    useEffect(() => {
      if (display) {
        document.body.style.overflow = 'hidden'
      }
      return () => {
        document.body.style.overflow = 'auto'
      }
    }, [display])

    const portalDiv = document.getElementById('modal-root')!

    return ReactDOM.createPortal(
      <AnimatePresence>
        {display && (
          <FocusTrap>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex-wrap bg-black fixed px-4   overflow-y-auto overflow-x-hidden inset-0  flex justify-center items-center bg-opacity-60 z-[60] bg-clip-padding"
            >
              <motion.div
                variants={variants}
                initial="initial"
                animate="entrance"
                exit="exit"
                transition={{
                  duration: 0.5,
                  easing: 'easeOut',
                }}
                ref={modalContentRef}
                className={[
                  'my-8 px-4 shadow-xl z-50 bg-white rounded-lg relative w-full',
                  maxWidth || 'max-w-md',
                ].join(' ')}
              >
                <div
                  className={[
                    'h-11 transform translate-y-[2px] py-8 mx-[2px] flex items-center justify-between  text-custom-astro-purple border-b border-skin-input-border',
                    title && ' w-full',
                  ].join(' ')}
                >
                  <div
                    className={[
                      'text-xl laptop:text-2xl font-bold text-center',
                      className?.title,
                    ].join(' ')}
                  >
                    {title}
                  </div>
                  <XIcon
                    className=" cursor-pointer stroke-current h-6 w-6"
                    tabIndex={0}
                    onKeyUp={(e) => {
                      if (e.code === 'Enter') {
                        close()
                      }
                    }}
                    onClick={close}
                  />
                </div>

                <div className=" py-4 min-h-[10rem] flex flex-col">
                  {children}
                </div>
              </motion.div>
            </motion.div>
          </FocusTrap>
        )}
      </AnimatePresence>,
      portalDiv
    )
  }
)

export default Modal
