import { createElement, useEffect, useRef, useState } from 'react'
import { ModalActionModel } from 'src/models/modal-action-model'

const useMountAndOpenModal = () => {
  const [modal, setModal] = useState<any>(null)
  const ref = useRef<ModalActionModel | null>(null)

  useEffect(() => {
    if (modal) {
      ref.current?.openModal()
    }
  }, [modal])

  const createModal = (element: any, props: any) => {
    if (!element) {
      return ref.current?.closeModal()
    }
    return setModal(
      createElement(element, {
        ...props,
        modalRef: ref,
      })
    )
  }

  return [modal, createModal]
}

export default useMountAndOpenModal
