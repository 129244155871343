import { useEffect, useState } from 'react'
import PaymentSummary from './PaymentSummary'
import { motion } from 'framer-motion'
import useMountAndOpenModal from 'src/hooks/useMountAndOpenModal'
import UnexpectedErrorModal from 'src/components/shared/UnexpectedErrorModal'
import { ChannelModel, InitDataModel } from 'src/models/InitDataModel'
import { Img } from 'src/components/elements'
import MakePayment from '../PaymentDetail/MakePayment'
import apiToUsed from 'src/helpers/apiToUsed'
import { useParams } from 'react-router-dom'
import axios from 'axios'

interface Props {
  initialData: InitDataModel
  setSelectedChannel: (x: ChannelModel | null) => void
  selectedChannel: ChannelModel | null
  setActiveStep: (x: number) => void
  setFinalData: (x: any) => void
  hashData: string
}

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: -40 },
  show: {
    opacity: 1,
    y: 0,
  },
}

const ChoosePayment: React.FC<Props> = ({
  initialData,
  setSelectedChannel,
  selectedChannel,
  setActiveStep,
  setFinalData,
  hashData,
}) => {
  const { merchant, code } = useParams()
  const [modal, createModal] = useMountAndOpenModal()
  const [loading, setLoading] = useState(false)

  const groups: {
    [x: string]: ChannelModel[]
  } =
    initialData &&
    initialData.channels.reduce(
      (groups: any, item: ChannelModel) => ({
        ...groups,
        [item.category.slug]: [...(groups[item.category.slug] || []), item],
      }),
      []
    )

  const handleSelectChannel = async (data: ChannelModel) => {
    if (data.is_disabled) {
      return createModal(UnexpectedErrorModal, {
        errMsg: 'The given channel is currently disabled.',
      })
    }

    const element = document.getElementById('payment-detail')

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }

    return setSelectedChannel(data)
  }

  const handleSubmit = async () => {
    // if true there is additional field
    if (
      initialData.biller.settings.enable_payer_fields_for_link_checkouts ===
        true ||
      (selectedChannel && selectedChannel.fields.length > 0)
    ) {
      return setActiveStep(2)
    } else {
      const dataToSubmit = {
        organization: merchant,
        channel_code: selectedChannel?.code,
        stringify_id: code,
      }
      setLoading(true)
      try {
        const { data } = await axios({
          method: 'post',
          baseURL: apiToUsed,
          data: dataToSubmit,
          url: '/v1/external/checkout',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${hashData}`,
          },
        })
        setLoading(false)
        if (data.data.web_payment_url) {
          return (window.location = data.data.web_payment_url)
        }
        return setFinalData(data?.data)
      } catch (err: any) {
        const errors = {
          errorCode: err.response?.status || err.code,
          errorFormField: err.response?.data.errors,
          errorMessage: err.response?.data.message || err.message,
        }
        setLoading(false)
        if (errors.errorFormField?.channel_code?.[0]) {
          return createModal(UnexpectedErrorModal, {
            errMsg: errors.errorFormField?.channel_code[0],
          })
        }
        return createModal(UnexpectedErrorModal, {
          errMsg: errors.errorMessage,
        })
      }
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [])

  return (
    <div className="grid auto-rows-min grid-cols-1 lg:grid-cols-[1fr,24.375rem] w-full gap-4 xl:gap-8 lg:gap-y-0 flex-1 items-start">
      {modal}
      <div
        className={[
          'flex flex-col  bg-white p-8 sm:p-12 rounded-xl justify-between max-w-[43.75rem] mx-auto w-full shadow-[0px_4px_20px_rgba(0,_0,_0,_0.1)]',
        ].join(' ')}
      >
        <div className=" space-y-12">
          <div className="flex flex-col space-y-8">
            <div className=" font-bold text-lg text-primary">
              Choose your payment method<span className="text-spice">*</span>
            </div>
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="flex flex-col space-y-8 w-full"
            >
              {initialData && initialData.channels?.length <= 0 && (
                <em className="text-base opacity-50">
                  No channel available yet.
                </em>
              )}
              {initialData &&
                Object.keys(groups).map((key, i) => (
                  <div key={i} className="flex flex-col space-y-4">
                    <div className=" font-bold opacity-50">
                      {groups[key][0]?.category?.name}
                    </div>
                    <div
                      className={[
                        'grid grid-cols-1 sm:grid-cols-2  gap-6',
                        loading ? 'opacity-50' : '',
                      ].join(' ')}
                    >
                      {groups[key].map((z) => (
                        <div className="relative flex flex-col" key={z.code}>
                          <motion.button
                            variants={item}
                            disabled={loading}
                            onClick={() => handleSelectChannel(z)}
                            onKeyUp={(e) => {
                              if (e.code === 'Enter') {
                                handleSelectChannel(z)
                              }
                            }}
                            tabIndex={0}
                            className={[
                              'flex items-center space-x-4 rounded-lg border-2 p-2 ',
                              !z.is_disabled
                                ? 'cursor-pointer'
                                : 'cursor-default opacity-50',
                              selectedChannel?.code === z.code
                                ? 'bg-[#D9EEFF] border-[#3887C4]'
                                : 'bg-[#F6F8FA] border-transparent',
                            ].join(' ')}
                          >
                            <Img
                              className="object-contain rounded-md"
                              src={z.logo}
                              alt={z.name || 'biller'}
                              height="70"
                              width="70"
                              draggable={false}
                            />

                            <div>
                              <div className=" font-bold">{z.name}</div>
                              {/* <div className=" lowercase">{z.code}</div> */}
                            </div>
                          </motion.button>
                          <div
                            className={[
                              'sm:col-span-2 select-none  overflow-y-hidden ease-linear origin-top animate-SlideUpFadeIn mt-4',
                              selectedChannel?.code === 'MAYA' &&
                              z.code === 'MAYA'
                                ? 'block sm:hidden'
                                : 'hidden',
                            ].join(' ')}
                          >
                            <Img
                              src="/images/artwork/qrph.png"
                              alt="qrph"
                              draggable={false}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </motion.div>
          </div>
        </div>
      </div>

      <div
        id="payment-detail"
        className={[
          'scroll-mt-8 lg:scroll-mt-[10.125rem] bg-white p-8 rounded-xl  max-w-[43.75rem] mx-auto w-full flex flex-col space-y-8 shadow-[0px_4px_20px_rgba(0,_0,_0,_0.1)] lg:sticky lg:top-[10.125rem] lg:row-start-auto',
        ].join(' ')}
      >
        <PaymentSummary
          initialData={initialData}
          selectedChannel={selectedChannel}
        />
        <MakePayment
          handleOnSubmit={() => handleSubmit()}
          disabled={loading || !selectedChannel}
        />
      </div>
    </div>
  )
}

export default ChoosePayment
