import { useState } from 'react'
import Nav from './Nav'
import CheckoutContent from 'src/pages-content/checkout'

const CheckoutPage = () => {
  const [activeStep, setActiveStep] = useState(1)

  return (
    <>
      <Nav activeStep={activeStep} />
      <div className="h-[22.5rem] w-full fixed top-0 inset-x-0 bg-primary -z-10"></div>
      <main className="max-w-[66.875rem] w-full min-h-screen  mx-auto flex-1  flex flex-col py-12 px-4 bg-[url('/public/images/artwork/techpay-background.png')] bg-no-repeat bg-bottom bg-fixed">
        <CheckoutContent
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      </main>
    </>
  )
}

export default CheckoutPage
